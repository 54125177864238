import { Link, Paper } from '@mui/material';

export const Impressum = () => {
  return (
    <div>
      <Paper
        elevation={0}
        style={{
          margin: 'auto',
          maxWidth: 500,
          background: '#fff',
          height: '100%',
          borderRadius: 5,
          gridRow: 2,
          gridColumn: 1
        }}>
        <div
          style={{
            margin: 20,
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <div>
            <h1>Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>
              Anja Stricker
              <br />
              Gustav-Heinemann-Ring 131
              <br />
              81739 M&uuml;nchen
            </p>

            <h2>Kontakt</h2>
            <p>
              Telefon: 01732862588
              <br />
              E-Mail: info@anja-stricker.de
            </p>

            <h2>Redaktionell verantwortlich</h2>
            <p>
              Anja Stricker
              <br />
              Gustav-Heinemann-Ring 131
              <br />
              81739 M&uuml;nchen
            </p>
          </div>
        </div>
      </Paper>
      <Link
        className="noHover"
        style={{
          fontSize: 18,
          textDecoration: 'none',
          color: '#fff',
          width: 'auto'
        }}
        href="/">
        Back Home
      </Link>
    </div>
  );
};
